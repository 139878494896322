.plan-list {
    display: flex;
    justify-content: center;
}

.plan {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 3rem;
    max-width: 35rem;
    border: 1px solid var(--light-gray-color);
    border-radius: 5px;
}

.plan-title {
    margin-bottom: 0.3rem;
    color: var(--brand-color);
    font-size: 2rem;
}

.plan-price {
    display: flex;
    align-items: baseline;
    color: var(--secondary-text-color);
}

.plan-amount {
    margin-right: 0.5rem;
    color: var(--darker-gray-color);
    font-size: 2.4rem;
    font-weight: 700;
}

.plan-feature-list {
    flex-grow: 1;
    margin: 2rem 0 3rem;
    padding: 0;
    list-style-type: none;
}

.plan-feature {
    margin: 0.6rem 0;
}

.plan-selector {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.plan-selector-button {
    position: relative;
}

.plan-selector-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.plan-selector-button + .plan-selector-button {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.plan-selector-button + .plan-selector-button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.plan-selector-button:hover {
    z-index: 10;
}

@media (max-width: 991px) {
    .plan {
        display: none;
    }

    .plan-selector-free + .plan-list .plan-free {
        display: flex;
    }

    .plan-selector-monthly + .plan-list .plan-monthly {
        display: flex;
    }

    .plan-selector-yearly + .plan-list .plan-yearly {
        display: flex;
    }
}
