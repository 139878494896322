.popular-wrapper {
    padding: 2rem 1.5rem 1.5rem;
}

.popular-title {
    margin-bottom: 1rem;
    color: var(--secondary-text-color);
    font-size: 1.2rem;
    text-transform: uppercase;
}

.popular-feed {
    display: flex;
    flex-wrap: wrap;
}

.popular {
    margin-right: 0.8rem;
    margin-bottom: 0.4rem;
    font-weight: 700;
}

.popular::before {
    content: "#";
}
