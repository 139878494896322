.pswp {
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  outline: none;
  overflow: hidden;
  top: 0;
  touch-action: none;
  width: 100%;
  z-index: 1500;
  -ms-touch-action: none;
  -webkit-text-size-adjust: 100%;
}
.pswp img {
  max-width: none;
}
.pswp--animate_opacity {
  opacity: 0.001;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
  will-change: opacity;
}
.pswp--open {
  display: block;
}
.pswp--zoom-allowed .pswp__img {
  cursor: zoom-in;
}
.pswp--zoomed-in .pswp__img {
  cursor: grab;
}
.pswp--dragging .pswp__img {
  cursor: grabbing;
}
.pswp__bg {
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.85);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
  width: 100%;
  will-change: opacity;
}
.pswp__scroll-wrap {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}
.pswp__container,
.pswp__zoom-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  touch-action: none;
}
.pswp__container,
.pswp__img {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.pswp__zoom-wrap {
  position: absolute;
  transform-origin: left top;
  transition: transform 333ms cubic-bezier(0.4, 0, 0.22, 1);
  width: 100%;
}
.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap {
  transition: none;
}
.pswp__container,
.pswp__zoom-wrap {
  backface-visibility: hidden;
}
.pswp__item {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.pswp__img {
  height: auto;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
}
.pswp__img--placeholder {
  backface-visibility: hidden;
}
.pswp__img--placeholder--blank {
  background: var(--black-color);
}
.pswp--ie .pswp__img {
  height: auto !important;
  left: 0;
  top: 0;
  width: 100% !important;
}
.pswp__error-msg {
  color: var(--secondary-text-color);
  font-size: 14px;
  left: 0;
  line-height: 16px;
  margin-top: -8px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
.pswp__error-msg a {
  color: var(--secondary-text-color);
  text-decoration: underline;
}
.pswp__button {
  appearance: none;
  background: none;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  display: block;
  float: right;
  height: 44px;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: relative;
  transition: opacity 0.2s;
  width: 44px;
}
.pswp__button:focus,
.pswp__button:hover {
  opacity: 1;
}
.pswp__button:active {
  opacity: 0.9;
  outline: none;
}
.pswp__button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.pswp__ui--over-close .pswp__button--close {
  opacity: 1;
}
.pswp__button,
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background: url(../images/default-skin.png) 0 0 no-repeat;
  background-size: 264px 88px;
  height: 44px;
  width: 44px;
}
@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
  .pswp--svg .pswp__button,
  .pswp--svg .pswp__button--arrow--left:before,
  .pswp--svg .pswp__button--arrow--right:before {
    background-image: url(../images/default-skin.svg);
  }
  .pswp--svg .pswp__button--arrow--left,
  .pswp--svg .pswp__button--arrow--right {
    background: none;
  }
}
.pswp__button--close {
  background-position: 0 -44px;
}
.pswp__button--share {
  background-position: -44px -44px;
}
.pswp__button--fs {
  display: none;
}
.pswp--supports-fs .pswp__button--fs {
  display: block;
}
.pswp--fs .pswp__button--fs {
  background-position: -44px 0;
}
.pswp__button--zoom {
  background-position: -88px 0;
  display: none;
}
.pswp--zoom-allowed .pswp__button--zoom {
  display: block;
}
.pswp--zoomed-in .pswp__button--zoom {
  background-position: -132px 0;
}
.pswp--touch .pswp__button--arrow--left,
.pswp--touch .pswp__button--arrow--right {
  visibility: hidden;
}
.pswp__button--arrow--left,
.pswp__button--arrow--right {
  background: none;
  height: 100px;
  margin-top: -50px;
  position: absolute;
  top: 50%;
  width: 70px;
}
.pswp__button--arrow--left {
  left: 0;
}
.pswp__button--arrow--right {
  right: 0;
}
.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  content: "";
  height: 30px;
  position: absolute;
  top: 35px;
  width: 32px;
}
.pswp__button--arrow--left:before {
  background-position: -138px -44px;
  left: 6px;
}
.pswp__button--arrow--right:before {
  background-position: -94px -44px;
  right: 6px;
}
.pswp__counter {
  color: var(--white-color);
  font-size: 11px;
  font-weight: 700;
  height: 44px;
  left: 0;
  line-height: 44px;
  padding: 0 15px;
  position: absolute;
  top: 0;
  user-select: none;
}
.pswp__caption {
  bottom: 0;
  left: 0;
  min-height: 44px;
  position: absolute;
  width: 100%;
}
.pswp__caption__center {
  color: var(--white-color);
  font-size: 11px;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 420px;
  padding: 25px 15px 30px;
  text-align: center;
}
.pswp__caption__center .post-caption-title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.pswp__caption__center .post-caption-meta-item + .post-caption-meta-item:before {
  content: "\02022";
  padding: 0 4px;
}
.pswp__caption--empty {
  display: none;
}
.pswp__caption--fake {
  visibility: hidden;
}
.pswp__preloader {
  direction: ltr;
  height: 44px;
  left: 50%;
  margin-left: -22px;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.25s ease-out;
  width: 44px;
  will-change: opacity;
}
.pswp__preloader__icn {
  height: 20px;
  margin: 12px;
  width: 20px;
}
.pswp__preloader--active {
  opacity: 1;
}
.pswp__preloader--active .pswp__preloader__icn {
  background: url(../images/preloader.gif) 0 0 no-repeat;
}
.pswp--css_animation .pswp__preloader--active {
  opacity: 1;
}
.pswp--css_animation .pswp__preloader--active .pswp__preloader__icn {
  animation: clockwise 500ms linear infinite;
}
.pswp--css_animation .pswp__preloader--active .pswp__preloader__donut {
  animation: donut-rotate 1000ms cubic-bezier(0.4, 0, 0.22, 1) infinite;
}
.pswp--css_animation .pswp__preloader__icn {
  background: none;
  height: 14px;
  left: 15px;
  margin: 0;
  opacity: 0.75;
  position: absolute;
  top: 15px;
  width: 14px;
}
.pswp--css_animation .pswp__preloader__cut {
  height: 14px;
  overflow: hidden;
  position: relative;
  width: 7px;
}
.pswp--css_animation .pswp__preloader__donut {
  background: none;
  border: 2px solid var(--white-color);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  height: 14px;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 14px;
}
@media screen and (max-width: 1024px) {
  .pswp__preloader {
    float: right;
    left: auto;
    margin: 0;
    position: relative;
    top: auto;
  }
}
@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-140deg);
  }
  100% {
    transform: rotate(0);
  }
}
.pswp__ui {
  opacity: 1;
  visibility: visible;
  z-index: 1550;
  -webkit-font-smoothing: auto;
}
.pswp__top-bar {
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.pswp__caption,
.pswp__top-bar,
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
  backface-visibility: hidden;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
  will-change: opacity;
}
.pswp--has_mouse .pswp__button--arrow--left,
.pswp--has_mouse .pswp__button--arrow--right {
  visibility: visible;
}
.pswp__ui--idle .pswp__top-bar {
  opacity: 0;
}
.pswp__ui--idle .pswp__button--arrow--left,
.pswp__ui--idle .pswp__button--arrow--right {
  opacity: 0;
}
.pswp__ui--hidden .pswp__top-bar,
.pswp__ui--hidden .pswp__caption,
.pswp__ui--hidden .pswp__button--arrow--left,
.pswp__ui--hidden .pswp__button--arrow--right {
  opacity: 0.001;
}
.pswp__ui--one-slide .pswp__button--arrow--left,
.pswp__ui--one-slide .pswp__button--arrow--right,
.pswp__ui--one-slide .pswp__counter {
  display: none;
}
.pswp__element--disabled {
  display: none !important;
}
.pswp--minimal--dark .pswp__top-bar {
  background: none;
}
