.burger {
    position: relative;
    margin-right: -7px;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.burger-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}

.burger-bar::before {
    content: "";
    position: absolute;
    left: 7px;
    width: 16px;
    height: 2px;
    background-color: var(--darker-gray-color);
    transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s;
}

.burger-bar:first-child::before {
    top: 14px;
    transform: translateY(-3px);
}

.burger-bar:last-child::before {
    bottom: 14px;
    transform: translateY(3px);
}

.menu-opened .burger .burger-bar {
    transition: transform 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
}

.menu-opened .burger .burger-bar:first-child {
    transform: rotate(45deg);
}

.menu-opened .burger .burger-bar:last-child {
    transform: rotate(-45deg);
}

.menu-opened .burger .burger-bar::before {
    transition: transform 0.1806s cubic-bezier(0.04, 0.04, 0.12, 0.96);
    transform: none;
}
