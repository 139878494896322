.notification {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 90;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 60px;
    border-top: 3px solid var(--green-color);
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 700;
    background-color: var(--white-color);
    transition-timing-function: var(--animation-base);
    transition-duration: 0.3s;
    transition-property: box-shadow, transform;
    transform: translateY(-100%);
    pointer-events: none;
}

.error .notification {
    border-top-color: var(--red-color);
}

.subscribe-success.notification-opened .notification-subscribe,
.signup-success.notification-opened .notification-signup,
.signin-success.notification-opened .notification-signin,
.checkout-success.notification-opened .notification-checkout,
.billing-success.notification-opened .notification-billing-update,
.success .notification-success,
.error .notification-error {
    visibility: visible;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    transform: translateY(0);
    pointer-events: unset;
}

.notification-closing .notification {
    box-shadow: none !important;
    transform: translateY(-100%) !important;
}

.notification-close {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -15px;
}

@media (max-width: 767px) {
    .notification {
        justify-content: flex-start;
        padding: 15px 60px 15px 20px;
    }
}
