symbol {
    fill: currentColor;
}

.icon {
    width: 20px;
    height: 20px;
}

.icon-loading {
    width: 26px;
    height: 26px;
}
