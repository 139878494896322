.u-text-format {
    font-size: 1.7rem;
    line-height: 1.8;
}

.u-text-format > * {
    margin-bottom: 0;
}

.u-text-format > * + * {
    margin-top: 3rem;
}

.u-text-format > *:first-child {
    margin-top: 0 !important;
}

.u-text-format > * + p,
.u-text-format > * + ul,
.u-text-format > * + ol {
    margin-top: 2rem;
}

.u-text-format > [id] {
    margin-top: 4rem;
}

.u-text-format > [id] + p {
    margin-top: 1.5rem;
}

.u-text-format h1 {
    font-size: 2.8rem;
    letter-spacing: -0.03rem;
}

.u-text-format h2 {
    font-size: 2.3rem;
    letter-spacing: -0.03rem;
}

.u-text-format h3 {
    color: var(--secondary-text-color);
    font-size: 2rem;
    letter-spacing: -0.03rem;
}

.u-text-format h4 {
    font-size: 1.9rem;
    font-weight: 700;
}

.u-text-format h5 {
    font-size: 1.6rem;
    font-weight: 700;
}

.u-text-format h6 {
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
}

.u-text-format a {
    color: var(--brand-color);
    font-weight: 700;
    text-decoration: underline;
}

.u-text-format ul li + li,
.u-text-format ol li + li {
    margin-top: 1rem;
}

.u-text-format > .kg-card,
.u-text-format > .kg-card + * {
    margin-top: 4.5rem;
}

.u-text-format hr,
.u-text-format hr + * {
    margin-top: 4rem;
}

.u-text-format blockquote,
.u-text-format blockquote + * {
    margin-top: 3rem;
}

.post-template .u-text-format h2,
.post-template .u-text-format h3 {
    scroll-margin-top: 8rem;
}

.page-template .u-text-format h2,
.page-template .u-text-format h3 {
    scroll-margin-top: 3rem;
}

.u-permalink {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    outline: none;
}

.u-placeholder {
    position: relative;
    z-index: 10;
    background-color: var(--lighter-gray-color);
}

.u-placeholder::before {
    content: "";
    display: block;
}

.u-placeholder.horizontal::before {
    padding-bottom: 50%;
}

.u-placeholder.rectangle::before {
    padding-bottom: 62.5%;
}

.u-placeholder.square::before {
    padding-bottom: 100%;
}

.u-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
