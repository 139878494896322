.account-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-image-placeholder {
    overflow: hidden;
    margin-bottom: 2rem;
    width: 70px;
    border-radius: 50%;
}

.account-name {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
}

.account-button-list {
    display: flex;
    margin-top: 2rem;
}

.account-button {
    margin: 0 0.4rem;
}

.account-subscription {
    margin-top: 4.5rem;
}

.account-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account-row + .account-row {
    margin-top: 1.5rem;
}

.account-row-title {
    margin-bottom: 0;
    color: var(--secondary-text-color);
    font-size: 1.6rem;
    font-weight: 700;
}

.account-row-value {
    font-size: 1.6rem;
    font-weight: 800;
}

.account-status {
    padding: 0.5rem 0.8rem;
    height: 100%;
    color: var(--white-color);
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    background-color: var(--green-color);
    border-radius: 3px;
}

.account-status-trialing {
    background-color: var(--orange-color);
}

.account-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
}

.account-cancel-link {
    font-size: 1.3rem;
}

.account-cancel-error {
    display: block;
    margin-top: 0.2rem;
    font-size: 1.4rem;
}
