.sticky {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    transition: transform 0.3s var(--animation-base);
    transform: translateY(-52px);
    backdrop-filter: blur(5px);
}

.sticky-title {
    flex-grow: 1;
    overflow: hidden;
    margin-right: 1rem;
    color: var(--darker-gray-color);
    font-size: 1.6rem;
    line-height: 1.3;
    font-weight: 800;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sticky-toc-button {
    flex-shrink: 0;
    margin-right: -6px;
}

.sticky-toc-button .icon {
    width: 24px;
    height: 24px;
}

.sticky-toc {
    visibility: hidden;
    position: absolute;
    top: 72px;
    right: 20px;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    width: 250px;
    text-align: left;
    background-color: var(--lighter-gray-color);
    opacity: 0;
    transition-timing-function: var(--animation-base);
    transition-duration: 0.2s;
    transition-property: opacity transform;
    transform: translateY(-10px);
}

.sticky-toc-link {
    padding: 0.6rem 1.7rem;
    font-size: 1.1rem;
    font-weight: 700;
    text-transform: uppercase;
}

.sticky-toc-link-indented::before {
    content: "-";
    margin-right: 0.5rem;
}

.toc-opened .sticky-toc {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.sticky-track {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--light-gray-color);
}

.sticky-progress {
    height: 100%;
    background-color: var(--brand-color);
    transition: transform 0.2s;
    transform: translate3d(-100%, 0, 0);
}

.sticky-visible .sticky {
    transform: translateY(0);
}

@media (max-width: 767px) {
    .sticky-title {
        font-size: 1.4rem;
        font-weight: 700;
    }
}
