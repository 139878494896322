.kg-gallery-container {
    display: flex;
    flex-direction: column;
}

.kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.kg-gallery-image {
    cursor: pointer;
}

.kg-gallery-image img {
    width: 100%;
    height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
    margin: 10px 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 10px;
}

.kg-bookmark-card {
    overflow: hidden;
    border: 1px solid var(--light-gray-color);
    border-radius: 4px;
}

.kg-bookmark-container {
    display: flex;
    overflow: hidden;
    text-decoration: none !important;
}

.kg-bookmark-container::after {
    display: none;
}

.kg-bookmark-container:hover {
    opacity: 1;
}

.kg-bookmark-content {
    overflow: hidden;
    padding: 25px;
}

.kg-bookmark-title {
    color: var(--darker-gray-color);
    font-size: 18px;
    line-height: 1.3;
    font-weight: 800;
}

.kg-bookmark-description {
    overflow: hidden;
    margin-top: 5px;
    color: var(--secondary-text-color);
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.kg-bookmark-metadata {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 13px;
    line-height: 1;
    font-weight: 700;
}

.kg-bookmark-icon {
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.kg-bookmark-author,
.kg-bookmark-publisher {
    color: var(--darker-gray-color);
}

.kg-bookmark-author + .kg-bookmark-publisher::before {
    content: "•";
    padding: 0 5px;
    font-family: serif;
}

.kg-bookmark-thumbnail {
    position: relative;
    flex-shrink: 0;
    margin-left: 20px;
    width: 220px;
}

.kg-bookmark-thumbnail img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 767px) {
    .kg-bookmark-container {
        flex-direction: column;
    }

    .kg-bookmark-thumbnail {
        margin-left: 0;
        width: 100%;
        height: 200px;
    }
}

.kg-image {
    margin-right: auto;
    margin-left: auto;
}

.kg-canvas {
    display: grid;
    grid-template-columns:
        [full-start]
        minmax(4vw, auto)
        [wide-start]
        minmax(auto, 140px)
        [main-start]
        min(640px, calc(100% - 8vw))
        [main-end]
        minmax(auto, 140px)
        [wide-end]
        minmax(4vw, auto)
        [full-end];
}

.kg-canvas > * {
    grid-column: main-start/main-end;
}

.kg-width-wide {
    grid-column: wide-start/wide-end;
}

.kg-width-full {
    grid-column: full-start/full-end;
}
