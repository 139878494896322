.featured-wrapper {
    margin-top: 6rem;
}

.featured-wrapper .u-placeholder {
    margin-bottom: 3rem;
}

.featured-wrapper .post-title {
    margin-bottom: 0;
    font-size: 1.8rem;
    font-weight: 700;
}

.featured-title {
    margin-bottom: 4.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--light-gray-color);
    color: var(--brand-color);
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
}
