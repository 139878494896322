.site {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.site-content {
    flex-grow: 1;
    padding: 6rem 0;
}

@media (max-width: 767px) {
    .site-content {
        padding: 3rem 0;
    }
}
