.navigation-previous .navigation-icon {
    margin-left: -2px;
}

.navigation-next .navigation-link {
    justify-content: flex-end;
}

.navigation-next .navigation-icon {
    margin-right: -2px;
}

.navigation-link {
    display: flex;
    align-items: center;
    width: 30px;
    height: 30px;
}
