.pagination,
.infinite-scroll-status,
body:not(.paged-next) .infinite-scroll-button {
    display: none;
}

.infinite-scroll-request {
    margin: 3rem auto 0;
    width: 40px;
    height: 40px;
    background-color: var(--darker-gray-color);
    border-radius: 50%;
    animation: scaleOut 1s infinite ease-in-out;
}

.infinite-scroll-action {
    display: flex;
    justify-content: center;
}

.infinite-scroll-button {
    margin-top: 4.5rem;
}

@keyframes scaleOut {
    0% {
        transform: scale(0);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}
