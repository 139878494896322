.auth {
    margin: 3rem auto 0;
    padding: 4rem 3rem 4.5rem;
    max-width: 500px;
    border-top: 5px solid var(--brand-color);
    background-color: var(--lighter-gray-color);
    border-radius: 5px;
}

.auth-title {
    margin-bottom: 0.8rem;
    font-size: 2.6rem;
}

.auth-subtitle {
    color: var(--secondary-text-color);
    font-size: 1.5rem;
    font-weight: 700;
}

.auth-form {
    margin-top: 3rem;
}

.auth-email {
    height: 50px;
}

.auth-footer {
    display: inline-block;
    margin-top: 2rem;
    font-size: 1.4rem;
    letter-spacing: -0.02rem;
}

.auth-footer-link {
    color: var(--brand-color);
    font-weight: 700;
    text-decoration: underline;
}
